import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import SignUp from "components/Auth/SignUp";
import Application from "components/Courses/Application";

const JoinFigma = () => {
  const [data, setData] = useState(null);
  const [scholar, setScholar] = useState(false);

  let price = "$99";
  if (scholar) {
    price = "$49";
  }
  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    setData(userData);

    let potentialReferral = sessionStorage.getItem("referralEmail");

    if (potentialReferral) {
      if (potentialReferral.includes("scholar")) {
        setScholar(true);
      }
    }
  }, []);

  return (
    <>
      <Helmet
        title="Join — Prototyping with Figma | Enlight"
        meta={[
          {
            name: "description",
            content:
              "Learn to build high-fidelity clickable prototypes in Figma in just two weeks. No experience necessary.              ",
          },
          {
            name: "og:title",
            content: "Prototyping with Figma | Enlight",
          },
          {
            name: "og:description",
            content:
              "Learn to build high-fidelity clickable prototypes in Figma in just two weeks. No experience necessary.              ",
          },
          {
            name: "og:url",
            content: "https://enlight.nyc/courses/figma",
          },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/courses/figma/share.png",
          },
          {
            name: "twitter:title",
            content: "Prototyping with Figma | Enlight",
          },
          {
            name: "twitter:description",
            content:
              "Learn to build high-fidelity clickable prototypes in Figma in just two weeks. No experience necessary.",
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/courses/figma/share.png",
          },
        ]}
      />
      <main className="relative mx-auto max-w-screen-xl">
        <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 z-10">
          <div className="text-center">
            <div
              className="py-1 bg-purple-800 hover:bg-purple-700 items-center text-purple-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer"
              role="alert"
            >
              <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
                Prototyping with Figma
              </span>
            </div>
          </div>
          <div className="text-center">
            <h2 className="text-4xl tracking-tight font-bold text-gray-900 ">
              Join the next cohort
            </h2>
            <p className="text-2xl text-gray-600">
              You've got the will, we've got the way.
            </p>
          </div>
        </main>

        <div className="p-4 z-0">
          <div className="bg-gray-100 p-4 max-w-lg mx-auto rounded text-center">
            <h1 className="text-base tracking-widest uppercase text-gray-700">
              Cohort #1
            </h1>
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Prototyping with Figma
            </h1>
            <h1 className="text-xl font-bold tracking-tight text-gray-700">
              March 21st — April 3th
            </h1>

            <div className="flex justify-center">
              <h1 className="flex items-center text-3xl tracking-tight text-gray-700 mr-2 line-through">
                $179
              </h1>
              <h1 className="text-5xl font-bold tracking-tight text-gray-900 mb-2">
                {price}
              </h1>
              <p className="flex items-center ml-2 text-gray-700 text-2xl">
                USD
              </p>
            </div>

            <ul className="text-center mx-auto w-64">
              <li className="flex items-center text-gray-800 p-1">
                <svg
                  className="h-5 w-5 text-green-600 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>{" "}
                </svg>
                Attend 5 live workshops
              </li>
              <li className="flex items-center text-gray-800 p-1">
                <svg
                  className="h-5 w-5 text-green-600 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>{" "}
                </svg>
                Build your own prototype
              </li>
              <li className="flex items-center text-gray-800 p-1">
                <svg
                  className="h-5 w-5 text-green-600 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>{" "}
                </svg>
                Exclusive community
              </li>

              <li className="flex items-center text-gray-800 p-1">
                <svg
                  className="h-5 w-5 text-green-600 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>{" "}
                </svg>
                Certificate of completion
              </li>
              <li className="flex items-center text-gray-800 p-1">
                <svg
                  className="h-5 w-5 text-green-600 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  {" "}
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>{" "}
                </svg>
                Lifetime access
              </li>
            </ul>
          </div>
        </div>
      </main>

      <div className="bg-white">
        <section className="mx-auto max-w-screen-xl">
          {data ? (
            <Application
              type={scholar ? "scholar" : "learner"}
              userData={data}
              cohort={"f0"}
            />
          ) : (
            <>
              <SignUp text={false} cohort={true} />
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default JoinFigma;
