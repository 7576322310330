import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import Markdown from "react-remarkable-with-plugins";

import {
  Container,
  Input,
  Select,
  Label,
  Waiver,
} from "components/Cohort/FormStyles";

import { loadStripe } from "@stripe/stripe-js";
import { navigate } from "@reach/router";
const stripePromise = loadStripe("pk_live_gYdkuJ4RWYpH86fFvMezXK3S");

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

class Application extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      location: "",
      timezone: "",
      occupation: "",
      gender: "",
      enlight: "",
      linkedin: "",
      referral: "",
      user_signature: "",
      user_signature_date: "",
      clicked: false,
      type: this.props.type,
      referred_by_user: false,
      referred_by_mentor: false,
      referred_by_michigan: false,
      cohort: this.props.cohort,
      slug: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
  }

  componentDidMount() {
    this.setState({
      name: this.props.userData.username,
      email: this.props.userData.email_address,
      enlight: this.props.userData.id,
    });

    if (this.props.cohort == "wd2") {
      this.setState({
        slug: "web-development",
      });
    } else if (this.props.cohort == "f0") {
      this.setState({
        slug: "figma",
      });
    }

    let potentialReferral = sessionStorage.getItem("referralEmail");

    if (potentialReferral) {
      if (potentialReferral.includes("mentor")) {
        this.setState({
          referral: potentialReferral,
          referred_by_mentor: true,
          type: "referral",
        });
      } else if (potentialReferral.includes("scholar")) {
        this.setState({
          referral: potentialReferral,
          type: "scholar",
        });
      } else if (potentialReferral.includes("michigan")) {
        this.setState({
          referral: potentialReferral,
          type: "michigan",
          referred_by_michigan: true,
        });
      } else {
        this.setState({
          referral: potentialReferral,
          referred_by_user: true,
          type: "referral",
        });
      }
    }
  }

  handleSubmit(event) {
    if (
      this.state.name == "" ||
      this.state.email == "" ||
      this.state.location == "" ||
      this.state.timezone == "" ||
      this.state.occupation == "" ||
      this.state.gender == "" ||
      this.state.enlight == "" ||
      this.state.linkedin == "" ||
      this.state.referral == "" ||
      this.state.user_signature == "" ||
      this.state.user_signature_date == ""
    ) {
      alert("Please fill out all fields before submitting.");
    } else {
      this.setState({ clicked: true });
      const submission = {
        type: this.state.type,
        name: this.state.name,
        email: this.state.email,
        location: this.state.location,
        timezone: this.state.timezone,
        occupation: this.state.occupation,
        gender: this.state.gender,
        enlight: this.state.enlight,
        linkedin: this.state.linkedin,
        referral: this.state.referral,
        user_signature: this.state.user_signature,
        user_signature_date: this.state.user_signature_date,
      };
      var data = new FormData();
      for (var key in submission) {
        data.append(key, submission[key]);
      }

      let sheetRequest = "";
      if (this.state.cohort == "wd2") {
        sheetRequest =
          "https://script.google.com/macros/s/AKfycbyWJR8-58TjYxPhuv1QI0OxFVuNX_MEW6vvvy98m3W9-OAeddP8ybYy/exec";
      } else if ((this.state.cohort = "f0")) {
        sheetRequest =
          "https://script.google.com/macros/s/AKfycbwqYt7JX6uaJ9dOt71KDqYMG9pLKqcjK6DarfuDkaQ5XYzjDq-FoEsMDw/exec";
      }

      fetch(sheetRequest, { method: "POST", body: data })
        .then((response) => {
          this.submitPayment();
        })
        .catch((error) => {
          console.error("Error!", error.message);
          alert("There was an error submitting your form. Please try again.");
        });
    }
    event.preventDefault();
  }

  submitPayment = async () => {
    let priceID = "";
    

    if (this.state.cohort == "wd2") {
      priceID = "price_1IPBzTDOlSv6RXxw2RFhgXiI";
    } else if (this.state.cohort == "f0") {
      priceID = "price_1IPCEXDOlSv6RXxwY7vTr175";
      if (this.state.type == "scholar") {
        // figma scholarship price
        priceID = "price_1IST9uDOlSv6RXxw6VklTiwR";
      }
    }

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceID, quantity: 1 }],
      mode: "payment",
      successUrl: `https://enlight.nyc/courses/${this.state.slug}/confirmed`,
      cancelUrl: `https://enlight.nyc/courses/${this.state.slug}/`,
      customerEmail: this.state.email,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  render() {
    return (
      <Container>
        <h1 className="text-2xl tracking-tight font-bold text-gray-900 mb-4 mt-4 text-center">
          {" "}
          Please complete this short form to join the cohort.{" "}
        </h1>
        <form onSubmit={this.handleSubmit}>
          <section>
            <Row>
              <Col sm={6}>
                <Input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  value={this.state.name}
                  onChange={(event) =>{
                    this.setState(
                      updateByPropertyName("name", event.target.value)
                    );
                    window.analytics.track("Registration Info", {
                      title: "Full Name",
                      location: "Courses Registration",
                      page: window.location.pathname,
                      pageTitle: document.title
                    });
                  }}
                />
              </Col>
              <Col sm={6}>
                <Input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={(event) =>{
                    this.setState(
                      updateByPropertyName("email", event.target.value)
                    );
                    window.analytics.track("Registration Info", {
                      title: "Email",
                      location: "Courses Registration",
                      page: window.location.pathname,
                      pageTitle: document.title
                    });
                  }}
                />{" "}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Input
                  type="text"
                  name="occupation"
                  placeholder="Occupation"
                  value={this.state.occupation}
                  onChange={(event) =>{
                    this.setState(
                      updateByPropertyName("occupation", event.target.value)
                    );
                    window.analytics.track("Registration Info", {
                      title: "Timezone",
                      location: "Courses Registration",
                      page: window.location.pathname,
                      pageTitle: document.title
                    });
                  }}
                />
              </Col>
              <Col sm={3}>
                <Input
                  type="text"
                  placeholder="Location"
                  value={this.state.location}
                  onChange={(event) =>{
                    this.setState(
                      updateByPropertyName("location", event.target.value)
                    );
                    window.analytics.track("Registration Info", {
                      title: "Location",
                      location: "Courses Registration",
                      page: window.location.pathname,
                      pageTitle: document.title
                    });
                  }}
                />{" "}
              </Col>
              <Col sm={3}>
                <Input
                  type="text"
                  placeholder="Timezone"
                  value={this.state.timezone}
                  onChange={(event) =>{
                    this.setState(
                      updateByPropertyName("timezone", event.target.value)
                    );
                    window.analytics.track("Registration Info", {
                      title: "Timezone",
                      location: "Courses Registration",
                      page: window.location.pathname,
                      pageTitle: document.title
                    });
                  }}
                />{" "}
              </Col>

              <Col sm={3}>
                <Select
                  name="gender"
                  value={this.state.gender}
                  onChange={(event) => {
                    this.setState(
                      updateByPropertyName("gender", event.target.value)
                    );
                    window.analytics.track("Registration Info", {
                      title: "Gender",
                      location: "Courses Registration",
                      page: window.location.pathname,
                      pageTitle: document.title
                    });
                  }}
                >
                  <option value="" disabled selected>
                    Gender
                  </option>

                  <option>Male</option>
                  <option>Female</option>
                  <option>Non-binary</option>
                  <option>Prefer not to say</option>
                </Select>
              </Col>
            </Row>
          </section>
          <br />

          <Row>
            <Col sm={12}>
              <Label>Please share a link to your LinkedIn profile.</Label>
              <Input
                name="linkedin"
                type="text"
                placeholder="https://linkedin.com/"
                value={this.state.linkedin}
                onChange={(event) => {
                  this.setState(
                    updateByPropertyName("linkedin", event.target.value)
                  );
                  window.analytics.track("Registration Info", {
                    title: "LinkedIn link",
                    location:"Courses Registration",
                    page: window.location.pathname,
                    pageTitle: document.title
                  });
                }}
              ></Input>
            </Col>
          </Row>
          {/* <Row>
            <Col sm={12}>
              <Label>
                Please join our{" "}
                <a
                  target="_blank"
                  className="text-blue-600"
                  href="https://discord.gg/8FwTpfN"
                >
                  Discord
                </a>{" "}
                and enter your username.{" "}
              </Label>
              <Input
                name="discord"
                type="text"
                placeholder="e.g. bot#1234"
                value={this.state.discord}
                onChange={event =>
                  this.setState(
                    updateByPropertyName("discord", event.target.value)
                  )
                }
              ></Input>
            </Col>
          </Row> */}

          <Row>
            <Col sm={12}>
              {this.state.referred_by_user ||
              this.state.referred_by_mentor ||
              this.state.referred_by_michigan ||
              this.state.type == "scholar" ? (
                <>
                  {/* <Label className="italic font-bold">
                   {" "}
                   (You were referred by {this.state.referral})
                 </Label> */}
                </>
              ) : (
                <>
                  <Label>How did you hear about us? </Label>
                  <Input
                    name="referral"
                    type="text"
                    placeholder=""
                    value={this.state.referral}
                    onChange={(event) =>{
                      this.setState(
                        updateByPropertyName("referral", event.target.value)
                      );
                      window.analytics.track("Registration Info", {
                        title: "Referral",
                        location: "Courses Registration",
                        page: window.location.pathname,
                        pageTitle: document.title
                      });
                    }}
                  ></Input>
                </>
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={12}>
              <Label>
                {" "}
                Terms of Service, Liability, and Photo Release Waiver{" "}
              </Label>
              <Waiver className="bg-gray-100 p-2">
                <Markdown>
                  <br />
                  **WAIVER OF LIABILITY AND HOLD HARMLESS AGREEMENT** 
                  <br />
                  ***By signing this waiver you agree to follow the Behavioural
                  Rules of Enlight as laid out in the [Enlight Terms of
                  Service](https://enlight.nyc/terms).***
                  <br />
                  1. I hereby RELEASE, WAIVE, DISCHARGE, AND COVENANT NOT TO SUE
                  Enlight, their officers, agents, or employees (hereinafter
                  referred to as RELEASEES) from any and all liability, claims,
                  demands, actions, and causes of action whatsoever arising out
                  of or related to any loss, damage, or injury, including death,
                  that may be sustained by me, or to any property belonging to
                  me, while participating in the Enlight Cohort, while in, on or
                  upon the premises where the activities are being conducted,
                  REGARDLESS OF WHETHER SUCH LOSS IS CAUSED BY THE NEGLIGENCE OF
                  THE RELEASEES, or otherwise and regardless of whether such
                  liability arises in tort, contract, strict liability, or
                  otherwise, to the fullest extent allowed by law.
                  <br />
                  <br />
                  2. I am fully aware of the risks and hazards connected with
                  the activities of participating in the Enlight Cohort, and I
                  am aware that such activities include the risk of injury and
                  even death, and I hereby elect to voluntarily participate in
                  said activities, knowing that the activities may be hazardous
                  to my property and me. I understand that Enlight does not
                  require me to participate in this activity. I voluntarily
                  assume full responsibility for any risks of loss, property
                  damage, or personal injury, including death that may be
                  sustained by me or any loss or damage to property owned by me,
                  as a result of being engaged in such an activities, WHETHER
                  CAUSED BY THE NEGLIGENCE OF RELEASEES or otherwise, to the
                  fullest extent allowed by law.
                  <br />
                  <br />
                  3. I further hereby AGREE TO INDEMNIFY AND HOLD HARMLESS the
                  RELEASEES from any loss, liability, damage, or costs,
                  including court costs and attorneys' fees that Releasees may
                  incur due to my participation in said activities, WHETHER
                  CAUSED BY NEGLIGENCE OF RELEASEES or otherwise, to the fullest
                  extent allowed by law.
                  <br />
                  <br />
                  4. It is my express intent that this Waiver and Hold Harmless
                  Agreement shall bind the members of my family and spouse, if I
                  am alive, and my heirs, assigns and personal representative,
                  if I am deceased, and shall be deemed as a RELEASE, WAIVER,
                  DISCHARGE, AND COVENANT NOT TO SUE the above-named RELEASEES.
                  I hereby further agree that this Waiver of Liability and Hold
                  Harmless Agreement shall be construed in accordance with the
                  laws of the State of New Jersey and that any mediation, suit,
                  or other proceeding must be filed or entered into only in New
                  Jersey. Any portion of this document deemed unlawful or
                  unenforceable is severable and shall be stricken without any
                  effect on the enforceability of the remaining provisions.
                  <br />
                  <br />
                  5. Once made, any Payment for the Enlight Cohort shall not be
                  refunded or refundable for any reason except cancellation
                  prior to the Cohort start date and time. In addition, failure
                  to comply with the first two project deadlines of the Enlight
                  Cohort will result in termination from the Program without
                  refund.
                  <br />
                  <br />
                  **Enlight ​PHOTO​ ​RELEASE​ ​FORM**
                  <br />
                  <br />
                  I hereby grant the Coordinators of the Enlight Cohort
                  permission to use my likeness in a photograph, video, or other
                  digital media in any and all of its publications, including
                  web-based publications, without payment or other
                  consideration. I understand and agree that all photos will
                  become the property of Enlight and may not be returned. I
                  hereby irrevocably authorize Enlight to edit, alter, copy,
                  exhibit, publish, or distribute these photos for any lawful
                  purpose. In addition, I waive any right to inspect or approve
                  the finished product wherein my likeness appears.
                  Additionally, I waive any right to royalties or other
                  compensation arising or related to the use of the photo. I
                  hereby hold harmless, release, and forever discharge Enlight
                  from all claims, demands, suits, and causes of action which I,
                  my heirs, representatives, executors, administrators, or any
                  other persons acting on my behalf or on behalf of my estate
                  have or may have by reason of this authorization. BY
                  REGISTRATION AND PARTICIPATION IN THE ENLIGHT COHORT, I
                  CERTIFY THAT I HAVE READ THIS DOCUMENT, AND I FULLY UNDERSTAND
                  AND AGREE TO ITS CONTENT. I AM AWARE THAT THIS IS A RELEASE OF
                  LIABILITY AND A CONTRACT AND I REGISTER FOR AND PARTICIPATE AT
                  THE EVENT AT MY OWN FREE WILL.
                  <br />
                  <br />I have read this waiver, release of liability, photo
                  release, consent, and Enlight Terms of Service and I am
                  signing it voluntarily.
                  <br />
                  Date Created: Jul 13, 2020 1:41 PM
                </Markdown>
              </Waiver>
              <Label>
                Once made, any Payment for the Enlight Cohort shall not be
                refunded or refundable for any reason except cancellation prior
                to the Cohort start date and time.
              </Label>
              <Label>
                I have read this waiver, release of liability, photo release,
                consent, and Enlight Terms of Service and I am signing it
                voluntarily. Signing my name constitutes as my legal signature.
              </Label>
              <Input
                name="signature"
                type="text"
                placeholder="Signature"
                value={this.state.user_signature}
                onChange={(event) =>{
                  this.setState(
                    updateByPropertyName("user_signature", event.target.value)
                  );
                  window.analytics.track("Registration Info", {
                    title: "Signature",
                    location: "Courses Registration",
                    page: window.location.pathname,
                    pageTitle: document.title
                  });
                }}
              ></Input>
              <br />
              <Input
                name="signature"
                type="text"
                placeholder="Today's date"
                value={this.state.user_signature_date}
                onChange={(event) =>{
                  this.setState(
                    updateByPropertyName(
                      "user_signature_date",
                      event.target.value
                    )
                  );
                  window.analytics.track("Registration Info", {
                    title: "Today's Date",
                    location: "Courses Registration",
                    page: window.location.pathname,
                    pageTitle: document.title
                  });
                }}
              ></Input>
            </Col>
          </Row>
          <br />
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 text-xl rounded"
            type="submit"
            onClick={()=> 
              window.analytics.track("Registration Info", {
                title: "Submit Registration",
                location: "Courses Registration",
                page: window.location.pathname,
                pageTitle: document.title
              })
            }
          >
            {this.state.clicked ? (
              "Loading..."
            ) : (
              <span>Continue to payment &#8250;</span>
            )}
          </button>
        </form>
        <br />
      </Container>
    );
  }
}

export default Application;
